import { CLOSE_PRELOADER, OPEN_PRELOADER } from '../types';

//Add preloader to layout
export default (state = false, action) => {
  switch (action.type) {
    case OPEN_PRELOADER:
      return true;
    case CLOSE_PRELOADER:
      return false;
    default:
      return state;
  }
};
