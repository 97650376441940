import React from "react";
import { Container, Row, Col } from 'reactstrap';
import imgSrc from './error.png';

export default function ContactInfo ({ isSingleProduct, className }) {
    return (
        <Container className={`text-center ${className ? className : ''}`}>
           <Row>
                <Col>
                    <img src={imgSrc} alt="product-not-found"/>
                    {isSingleProduct
                        ? <h2>Продукта не е намерен</h2> 
                        : <h2>Няма намерени продукти</h2> 
                    }
                    
                </Col>
           </Row>
        </Container>
    )
}
