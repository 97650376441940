import React from 'react';
import Pagination from '@mui/material/Pagination';
import PropTypes from 'prop-types'
import { priceRangeFilterSelector, shoeTypeFilterSelector, sizesFilterSelector, brandsFilterSelector } from '../../../store/selectors';
import { useSelector } from 'react-redux';
import "./style.scss"

function PaginationProducts({ getProductsApi, totalPages }) {
  const priceRangeFilter = useSelector(priceRangeFilterSelector);
  const shoeTypeFilter = useSelector(shoeTypeFilterSelector);
  const sizesFilter = useSelector(sizesFilterSelector);
  const brandsFilter = useSelector(brandsFilterSelector);

  const handlePageChange = (e, page) => {
    const params = {};

    if (priceRangeFilter[0]) params.minPriceRange = priceRangeFilter[0];
    if (priceRangeFilter[1]) params.maxPriceRange = priceRangeFilter[1];
    if (shoeTypeFilter) params.shoeType = shoeTypeFilter;
    if (sizesFilter) params.size = sizesFilter;
    if (brandsFilter) params.brand = brandsFilter;

    getProductsApi(page, 9, params, true);
  }
  
  return (
    <Pagination
      count={totalPages}
      variant="outlined"
      shape="rounded"
      onChange={handlePageChange}
    />
  );
}

PaginationProducts.propTypes = {
  totalPages: PropTypes.number.isRequired,
  getProductsApi: PropTypes.func.isRequired,
};

export default PaginationProducts;