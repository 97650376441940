import React from "react";
import slideOneBackgroundImage from "./slide-1.jpg";
import BaseButton from "../../../common/Buttons/BaseButton/BaseButton";
import './style.scss'; 

export default function Hero() {
  return (
    <div className="hero-section" style={{ backgroundImage: `url(${slideOneBackgroundImage})`}}>
      <div className="hero-banner">
        <h1>Втора употреба ски оборудване</h1>
        <p>в бургас</p>
        <BaseButton noHover text={"разгледай"} onClick={() => window.scrollTo(50,560)}/>
      </div>
    </div>
  );
}