import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import store from '../store/store';
import ProductNotFound from '../components/common/ProductNotFound';
import ProductItem from '../components/common/ProductItem';
import PaginationProducts from '../components/common/PaginationProducts';
import { getProducts } from '../api';
import ProductFilters from "../components/common/ProductFilters";
import useFirstRender from "../hooks/useFirstRender";
import { openPreloaderAction, closePreloaderAction } from '../store/actions';

export default function ProductsLayout ({ productType }) {
    const [products, setProducts] = useState([]);
    const [totalPages, setTotalPages] = useState(0);

    const isFirstRender = useFirstRender();

    function onLoad() {
        window.scrollTo(0, 0)
        getProductsApi(1,9);
    }

    useEffect(() => {
        onLoad();
    },[])


    useEffect(() => {
        onLoad();
  },[productType])

    const getProductsApi = (page, limit, params = {}, scrollToTop = false) => {
        store.dispatch(openPreloaderAction());
        
        params['productType'] = productType;
        
        getProducts(page, limit, params)
            .then((data) => {
                const { total_pages, items } = data.data;
                
                setTotalPages(total_pages);
                setProducts(items);
            })
            .catch((err) => console.error('err :', err))
            .finally(() => {
                if(scrollToTop) {
                    window.scrollTo(0, 0)
                }

                store.dispatch(closePreloaderAction())
            })
    }

    return (
        <Container className="mt-4 mb-5">
            <Row>
                <Col xs="12" md="3">
                    <ProductFilters productType={productType} getProductsApi={getProductsApi} />
                </Col>
                <Col xs="12" md="9">
                    <Row className="mb-5">
                        {console.log("products :", products)}
                        {products.length
                            ? products.map((product) =>
                                <Col className="mb-4" key={product.article_number} xl="4" lg="4" sm="6">
                                    <ProductItem product={product}/>
                                </Col>)
                            : isFirstRender
                                ? <></>
                                : <ProductNotFound />
                        }
                    </Row>

                    {(products.length && totalPages > 1)
                        ? <Row className="mb-4">
                            <Col>
                                <PaginationProducts getProductsApi={getProductsApi} setProducts={setProducts} totalPages={totalPages}/>
                            </Col>
                         </Row>
                        : <></>
                    }
                </Col>
            </Row>
        </Container>
    )
}
