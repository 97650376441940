import Contact from '../components/pages/Contact';
import Home from '../components/pages/Home';
import Product from '../components/pages/Product';
import MainLayout from '../layouts/MainLayout';
import ProductsLayout from '../layouts/ProductsLayout';

const routesConfig = {
  productPage: {
    path: '/product/:articleNumber',
    element: Product,
    layout: MainLayout,
  },
  snowboardsPage: {
    path: '/snowboards',
    element: ProductsLayout,
    layout: MainLayout,
    elementParams: {
      productType: 'snowboard'
    },
  },
  skiPage: {
    path: '/ski',
    element: ProductsLayout,
    elementParams: {
      productType: 'ski'
    },
    layout: MainLayout
  },
  shoesPage: {
    path: '/shoes',
    element: ProductsLayout,
    elementParams: {
      productType: 'shoe'
    },
    layout: MainLayout
  },
  contactPage: {
    path: '/contact',
    element: Contact,
    layout: MainLayout,
  },
  homePage: {
    path: '/*',
    element: Home,
    layout: MainLayout
  },
};

export default routesConfig;
