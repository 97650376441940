import React from 'react';
import PropTypes from 'prop-types'
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import Preloader from '../components/common/Preloader';

function MainLayout({ children }) {
  return (
    <>
      <Header />
      <div className="content">
        {children}
      </div>
      <Footer />
      <Preloader />
    </>
  );
}

MainLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default MainLayout;