import React from 'react';
import { Spinner } from 'reactstrap';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types'
import { isPreloaderLoadingSelector } from '../../../store/selectors';
import "./style.scss"

function Preloader({ className }) {
  const isPreloaderLoading = useSelector(isPreloaderLoadingSelector);

  return (
    <div className={`preloader black-overlay${className || ''} ${isPreloaderLoading ? 'show-in' : 'show-out'}`}>
      <Spinner animation="grow"/>
    </div>
  );
}

Preloader.propTypes = {
  className: PropTypes.string,
};

export default Preloader;