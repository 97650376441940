import { combineReducers } from 'redux';

import preloaderReducer from './preloaderReducer';
import priceRangeFilterReducer from './priceRangeFilterReducer';
import shoeTypeFilterReducer from './shoeTypeFilterReducer';
import sizesFilterReducer from './sizesFilterReducer';
import brandsFilterReducer from './brandsFilterReducer';

const rootReducer = combineReducers({
  preloader: preloaderReducer,
  priceRangeFilter: priceRangeFilterReducer,
  shoeTypeFilter: shoeTypeFilterReducer,
  sizesFilter: sizesFilterReducer,
  brandsFilter: brandsFilterReducer
});

export default rootReducer;
