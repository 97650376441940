import { map, pipe, values } from 'ramda';
import React from 'react';
import { Route } from 'react-router';
import { Routes } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import routesConfig from './routes-config';

const mapRoutes = pipe(
  map(({ path, element: Element, elementParams, layout: Layout, ...rest }) => (
    <Route
      key={uuidv4()}
      path={path}
      {...rest}
      element={
        <Layout>
          <Element  key={window.location.pathname} {...elementParams}/>
        </Layout>
      }
    ></Route>
  )),
  values,
);

const Router = () => <Routes>{mapRoutes(routesConfig)}</Routes>;

export default Router;
