import React from 'react';
import { Provider } from 'react-redux';
import store from './store/store';
import Router from './routes/router';
import "./styles/app.scss";
import { BrowserRouter } from 'react-router-dom';

function App() {
  return (
    <div className="app">
      <Provider store={store}>
        <BrowserRouter>
            <Router/>
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
