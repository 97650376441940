import React from "react";
import './style.scss'; 
import { Container, Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";
import categorieBootsItemImg from "./boots.jpg";
import categorieSkisItemImg from "./skis.jpg";
import categorieSnowboardsItemImg from "./snowboards.webp";

const categoriesItems = [
    {
        href: "/ski",
        img: categorieSkisItemImg,
        text: "Ски"
    },
    {
        href: "/shoes",
        img: categorieBootsItemImg,
        text: "Обувки"
    },
    {
        href: "/snowboards",
        img: categorieSnowboardsItemImg,
        text: "Сноуборд дъски"
    }
]

export default function CategoriesSection () {
    return (
        <Container className="categories-section">
            <Row>
                <Col>
                    <h3 className="mb-4">Категории</h3>
                </Col>  
            </Row>
            <Row>
                {categoriesItems.map((item, i) => {
                    const { href, img, text } = item;

                    return <Col key={`${i}-${text}`} xs="12" md="4">
                                <Link to={href}>
                                    <div className="categories-item-wrapper box-shadow-hover">
                                        <div className="categories-item" style={{ backgroundImage: `url(${img})` }}>
                                            <p>{text}</p>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                })}
            </Row>
        </Container>
    )
}
