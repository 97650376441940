import React from "react";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types'
import './style.scss'; 
import OrderButton from "../Buttons/OrderButton";

function ProductItem ({product}) {
    const { article_number, images, price, discount_price, title, discount_percentages } = product;
    
    return (
        <div className="product-item box-shadow-hover">
            {discount_percentages
                ? <span className="discount-container">
                    -{discount_percentages}%
                </span>
                : <></>
            }
            <Link to={`/product/${article_number}`}>
                <div className="product-img">
                    <img src={images[0]?.url} alt="product img"/>
                </div>
                <div className="product-title">
                    <p>{title}</p>
                </div>
            </Link>
            <div className="product-price">
                {discount_price && <span className="price">{discount_price}, 00 лв.</span>}
                {discount_price !== price && <span className="discount-price">{price}, 00 лв.</span>}
            </div>
            <OrderButton/>
        </div>
    )
}

ProductItem.propTypes = {
    product: PropTypes.shape({
        article_number: PropTypes.string.isRequired,
        images: PropTypes.arrayOf(PropTypes.object).isRequired,
        price: PropTypes.number.isRequired,
        discount_price: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
    }),
};

export default ProductItem;