import React, { useState, useEffect } from "react";
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types'

import PriceRanceFilter from './PriceRangeFilter';
import ShoeTypeFilter from './ShoeTypeFilter';
import SizeFilter from './SizeFilter';
import BrandFilter from './BrandFilter';
import BaseButton from "../Buttons/BaseButton";

import useFirstRender from "../../../hooks/useFirstRender";
import { getFilters } from '../../../api';
import store from '../../../store/store';
import { openPreloaderAction, closePreloaderAction, setSizesFilterAction, setBrandsFilterAction } from '../../../store/actions';

import './style.scss'; 

function ProductFilters ({ getProductsApi, productType }) {
    const [filters, setFilters] = useState({
        selectedMinPriceRange: 0,
        selectedMaxPriceRange: 0,
        selectedSizes: [],
        selectedShoeType: '',
        selectedBrands: []
    });

    const isMobile = window.screen.width < 768;

    const [isFiltersShown, setIsFiltersShown] = useState(true);

    const isFirstRender = useFirstRender();

    const [defaultMinPriceRange, setDefaultMinPriceRange] = useState(0);
    const [defaultMaxPriceRange, setDefaultMaxPriceRange] = useState(0);
    const [defaultSizes, setDefaultSizes] = useState([]);
    const [defaultBrands, setDefaultBrands] = useState([]);

    const showHideFilters = () => {
        setIsFiltersShown(!isFiltersShown)
    }

    useEffect(() => {
        isMobile && setIsFiltersShown(false);
        getFiltersApi(productType, {})
    }, []);

    useEffect(() => {
        isMobile && setIsFiltersShown(false);
        getFiltersApi(productType, {})
    }, [productType]);

    useEffect(() => {
        store.dispatch(setSizesFilterAction(filters.selectedSizes));
    }, [filters.selectedSizes]);

    useEffect(() => {
        store.dispatch(setBrandsFilterAction(filters.selectedBrands));
    }, [filters.selectedBrands]);

    useEffect(() => {
        const { selectedMinPriceRange,
            selectedMaxPriceRange,
            selectedSizes,
            selectedShoeType,
            selectedBrands
        } = filters;

        const params = {};

        if (selectedMinPriceRange) params.minPriceRange = selectedMinPriceRange;
        if (selectedMaxPriceRange) params.maxPriceRange = selectedMaxPriceRange;
        if (selectedShoeType) params.shoeType = selectedShoeType;
        if (!isFirstRender) params.size = selectedSizes;
        if (!isFirstRender) params.brand = selectedBrands;

        if(Object.keys(params).length !== 0) {
            const isScrollToTop = !isMobile;
            getProductsApi(1, 9, params, isScrollToTop)
            getFiltersApi(productType, params, !!selectedShoeType)
        };
    }, [filters]);

    const getFiltersApi = (productType, params, skipPriceRange) => {
        store.dispatch(openPreloaderAction());

        getFilters(productType, params)
            .then((data) => {
                const { 
                    sizes,
                    maxPriceRange,
                    minPriceRange,
                    brands
                } = data.data;

                setDefaultSizes(sizes);
                setDefaultBrands(brands);

                if(!skipPriceRange) {
                    setDefaultMaxPriceRange(maxPriceRange);
                    setDefaultMinPriceRange(minPriceRange);
                }
            })
            .catch((err) => console.error('err :', err))
            .finally(() => store.dispatch(closePreloaderAction()))
    }

    return (
        <Row>
            {isMobile &&
                <Col xs="12" className="text-center show-hide-btn">
                        <BaseButton className="mb-4" text={isFiltersShown ? "скрий филтри" : "покажи филтри"} onClick={showHideFilters}/>
                </Col>
            }
            {isFiltersShown &&
                <>
                    <Col xs="12">
                        <PriceRanceFilter
                            defaultMinPriceRange={defaultMinPriceRange}
                            defaultMaxPriceRange={defaultMaxPriceRange}
                            setFilters={setFilters}
                        />
                    </Col>
                    {productType === 'shoe' &&
                        <Col xs="12">
                            <ShoeTypeFilter
                                selectedShoeType={filters.selectedShoeType}
                                setFilters={setFilters}
                            />
                        </Col>
                    }
                    <Col xs="6" md="12">
                        <SizeFilter selectedSizes={filters.selectedSizes} setFilters={setFilters} sizes={defaultSizes}/>
                    </Col>
                    <Col xs="6" md="12">
                        <BrandFilter selectedBrands={filters.selectedBrands}  setFilters={setFilters} brands={defaultBrands}/>
                    </Col>
                    
                    {isMobile &&
                        <Col xs="12" className="text-center show-hide-btn">
                                <BaseButton className="mb-4" text={isFiltersShown ? "скрий филтри" : "покажи филтри"} onClick={showHideFilters}/>
                        </Col>
                    }
                </>
            }
        </Row>
    )
}

ProductFilters.propTypes = {
    getProductsApi: PropTypes.func.isRequired,
    productType: PropTypes.string.isRequired
};

export default ProductFilters;