import React from "react";
import './style.scss'; 
import EmailIcon from "../../images/Icons/EmailIcon";
import FacebookIcon from "../../images/Icons/FacebookIcon";
import ViberIcon from "../../images/Icons/ViberIcon";
import { Container, Row, Col } from 'reactstrap';

export default function ContactInfo () {
    return (
        <Container className="contact-info pt-5 pb-5">
            <Row>
                <Col>
                    <h3 className="mb-4">Свържете се с нас</h3>
                </Col>  
            </Row>
            <Row>
                <Col xs="12" md="4" className="mt-4">
                    <EmailIcon/>
                    <p>skiburgas@gmail.com</p>
                </Col>
                <Col xs="12" md="4" className="mt-4">
                    <ViberIcon />
                    <p>0883303396</p>
                </Col>
                <Col xs="12" md="4" className="mt-4">
                    <a 
                        href="https://www.facebook.com/people/%D0%92%D1%82%D0%BE%D1%80%D0%B0-%D1%83%D0%BF%D0%BE%D1%82%D1%80%D0%B5%D0%B1%D0%B0-%D1%81%D0%BA%D0%B8-%D0%BE%D0%B1%D0%BE%D1%80%D1%83%D0%B4%D0%B2%D0%B0%D0%BD%D0%B5-%D0%A1%D0%BA%D0%B8-%D0%91%D1%83%D1%80%D0%B3%D0%B0%D1%81/61565100449273/"
                        target="_blank"
                    >
                        <FacebookIcon/>
                        <p>facebook/skiburgas</p>
                    </a>
                </Col>
            </Row>
        </Container>
    )
}
