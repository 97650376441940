import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import logo from './logo.png';
import './style.scss'; 

export default function Header () {

    return (
        <Container fluid className="header">
            <Container>
                <Row className="align-items-center pt-2 pb-2">
                    <Col xs={12} sm={12} md={4}>
                        <div className="logo-container">
                            <Link to="/">
                                <img src={logo} alt="email-icon"/>
                            </Link>
                            <Link className="logo" to="/">
                            <span>Ски Бургас</span>
                            </Link>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={8}>
                        <nav className="header-navigation">
                            <ul>
                                <li>
                                    <Link to="/snowboards">Сноуборд дъски</Link>
                                </li>
                                <li>
                                    <Link to="/ski">Ски</Link>
                                </li>
                                <li>
                                    <Link to="/shoes">Обувки</Link>
                                </li>
                                <li>
                                    <Link to="/contact">Контакти</Link>
                                </li>
                            </ul>
                        </nav>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}
