import React from "react";
import './style.scss'; 
import ContactInfo from "../../common/ContactInfo";
import { Container } from 'reactstrap';
import CatergoriesSection from "./CategoriesSection/CategoriesSection";
import LastAddedProducts from "../../common/LastAddedProducts/LastAddedProducts";
import LeafletMap from "../../common/LeafletMap";
import LightBackground from "../../hoc/LightBackground";
import Hero from "./Hero";

export default function Home () {
    return (
        <Container fluid className="home-page">
            <Hero />
            <CatergoriesSection/>
            <LightBackground>
                <LastAddedProducts />
            </LightBackground>
            <ContactInfo/>
            <LeafletMap className={'mb-5'} />
        </Container>
    )
}
