import React from "react";
import './style.scss'; 

export default function Footer () {
    return (
        <div className="footer">
            <div className="copyright">&copy;{ new Date().getFullYear() } <span>SkiBurgas</span>.com</div>
        </div>
    )
}
