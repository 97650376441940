import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { useParams } from "react-router-dom";
import ImageGallery from "react-image-gallery";
import OrderButton from "../../common/Buttons/OrderButton";
import ProductNotFound from '../../common/ProductNotFound';
import store from '../../../store/store';
import { openPreloaderAction, closePreloaderAction } from '../../../store/actions';
import { getProduct } from "../../../api";
import LastAddedProducts from "../../common/LastAddedProducts/LastAddedProducts";
import LightBackground from "../../hoc/LightBackground";
import './style.scss';

export default function Product () {
    const [ product, setProduct ] = useState({});
    const [ isApiLoading, setIsApiLoading ] = useState(false);
    const [ productImages, setProductImages ] = useState([]);
    const { articleNumber } = useParams();

    useEffect(() => {
        getProductApi(articleNumber);
    }, []);

    useEffect(() => {
        getProductApi(articleNumber);
    }, [articleNumber]);

    const getProductApi = (articleNumber) => {
        window.scrollTo(0, 0);
        store.dispatch(openPreloaderAction());
        setIsApiLoading(true);

        getProduct(articleNumber)
            .then((data) => {
                const product = data.data
                const images = [];

                product.images.forEach((img) => {
                    const imgObj =  {
                        original: img.url,
                        thumbnail: img.url,
                        originalHeight: "700px",
                        originalWidth: "700px",
                        thumbnailHeight: "100px",
                        thumbnailwidth: "100px",
                    };

                    images.push(imgObj);
                })
                setProductImages(images);
                setProduct(product);
            })
            .catch((err) => console.error('err :', err))
            .finally(() => {
                setIsApiLoading(false);
                store.dispatch(closePreloaderAction());
            });
    }

    const {
        title,
        product_type,
        brand,
        size,
        price,
        discount_price,
        discount_percentages
    } = product;

    const categories = {
        ski: "Ски",
        shoe: "Обувки",
        snowboard: "Сноуборд дъски"
    };

    return (
        <Container fluid className="product-page mt-4 p-0">
            {Object.keys(product).length
                ? <>
                    <Container className="mb-5">
                        <Row>
                            <Col className="mb-3 position-relative" xs="12" md="6">
                                {discount_percentages 
                                    ? <span className="discount-container">
                                        -{discount_percentages}%
                                        </span>
                                    : <></>
                                }
                                {productImages && 
                                    <ImageGallery
                                        thumbnailPosition="bottom"
                                        items={productImages}
                                        showFullscreenButton={false}
                                        showPlayButton={false}
                                        slideDuration={200}
                                    />
                                }
                            </Col>
                            <Col xs="12" md="6" className="product-details">
                                <h4>{title}</h4>
                                <Row className="category-brand">
                                    <Col xs="12">
                                        <span>Категория: </span>
                                        <span>{categories[product_type]}</span>
                                    </Col>
                                    <Col xs="12">
                                        <span>Производител: </span>
                                        <span>{brand}</span>
                                    </Col>
                                    <Col xs="12">
                                        <span>Размер: </span>
                                        <span>{size}</span>
                                    </Col>
                                </Row>
                                <div className="product-details-id">
                                    <span>Артикулен номер №:</span>
                                    <span>{articleNumber}</span>
                                </div>
                                <div className="product-price-details">
                                    {discount_price && <h2>{discount_price} лв.</h2>}
                                    {discount_percentages ? <span>{price} лв.</span> : <></>}
                                </div>
                                <div className="order-btn-product">
                                    <OrderButton />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <LightBackground>
                            <LastAddedProducts />
                    </LightBackground>
                </>
                :  isApiLoading
                    ? <></>
                    : <ProductNotFound className="mb-5 mt-5" isSingleProduct/>
            }
        </Container>
    )
}