import React, { useEffect } from "react";
import ContactInfo from "../../common/ContactInfo";
import { Container } from "reactstrap";
import LeafletMap from '../../common/LeafletMap';

export default function Contact () {
    useEffect(() => {
        window.scrollTo(0, 0)
    },[])

    return (
        <Container className="mt-4">
            <ContactInfo />
            <LeafletMap />
        </Container>
    )
}