import axios from "axios";

const apiBaseUrl = 'https://api.skiburgas.com';
const subUrl = '/product';

export function getProducts(page, limit, params) {
    return axios({
        method: 'get',
        url: `${apiBaseUrl}${subUrl}/list/${page}/${limit}`,
        withCredentials: false,
        params,
      });
}

export function getFilters(productType, params) {
    return axios({
        method: 'get',
        url: `${apiBaseUrl}${subUrl}/filters?productType=${productType}`,
        withCredentials: false,
        params
      });
}

export function getProduct(articleNumber) {
    return axios({
        method: 'get',
        url: `${apiBaseUrl}${subUrl}/${articleNumber}`,
        withCredentials: false
      });
}