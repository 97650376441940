import React from "react";
import { Input, Label } from 'reactstrap';
import PropTypes from 'prop-types'

function BrandFilter ({ selectedBrands, brands, setFilters }) {
    const onChangeSize = (e, brand) => {
        const isChecked = e.target.checked;

        if(isChecked) {
            setFilters((prevFilters) => ({
                ...prevFilters,
                selectedBrands: [...selectedBrands, brand]
            }));
        } else {
            setFilters((prevFilters) => ({
                ...prevFilters,
                selectedBrands: selectedBrands.filter(value => value !== brand)
            }));
        }

    }

    return (
        <div className="product-filter">
            <h4>ПРОИЗВОДИТЕЛИ</h4>
            {brands.map((brand, i) => {
                return <div key={`${i}-${brand}`}  className="mb-1">
                            <Label className="filter-checkbox">
                                <Input
                                    type="checkbox"
                                    value={brand}
                                    checked={selectedBrands.includes(brand)}
                                    onChange={(e) => onChangeSize(e, brand)}
                                />
                                <span>{brand}</span>
                            </Label>
                        </div>
            })}
            
        </div>
    )
}

BrandFilter.propTypes = {
    selectedBrands: PropTypes.arrayOf(PropTypes.string).isRequired,
    brands: PropTypes.arrayOf(PropTypes.string).isRequired,
    setFilters: PropTypes.func.isRequired
};

export default BrandFilter;
