import React from "react";
import PropTypes from 'prop-types'
import './style.scss'; 

function ContactInfo ({ children }) {
    return (
        <div className="light-background">
            {children}
        </div>
    )
}

ContactInfo.propTypes = {
    children: PropTypes.element.isRequired,
};
  
export default ContactInfo;