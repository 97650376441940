import { SET_BRANDS_FILTER } from '../types';

export default (state = [], action) => {
  switch (action.type) {
    case SET_BRANDS_FILTER:
      return action.payload;
    default:
      return state;
  }
};
