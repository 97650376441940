import React from "react";
import PropTypes from 'prop-types'

function BaseButton ({ className, noHover, text, onClick }) {
    return (
        <button 
            className={`default-btn base-btn ${className ? className : ''} ${noHover ? 'no-hover' : ''}`}
            onClick={onClick}
        >
          {text}
        </button>
    )
}

BaseButton.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  noHover: PropTypes.bool,
  onClick: PropTypes.func
};

export default BaseButton;