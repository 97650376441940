import React from "react";
import { Button } from 'reactstrap';
import PropTypes from 'prop-types'
import './style.scss'; 
import SkiIcon from "../../../images/Icons/SkiIcon";
import SnowboardIcon from "../../../images/Icons/SnowboardIcon";
import store from '../../../../store/store';
import { setShoeTypeFilterAction } from '../../../../store/actions';

function ShoeTypeFilter ({ setFilters, selectedShoeType }) {
    const skiShoe = "Ски обувки";
    const snowboardShoe = "Сноуборд обувки";

    const skiShoeType = "ski";
    const snowboardShoeType = "snowboard";

    const onUpdate = (shoeType) => {
        if(shoeType) {
            setFilters((prevFilters) => ({
                ...prevFilters,
                selectedShoeType: shoeType,
                selectedSizes: [],
                selectedBrands: []
            }));
        };

        store.dispatch(setShoeTypeFilterAction(shoeType));
    }
    
    return (
        <div className="product-filter shoe-type-filter">
            <h4>ВИД</h4>
            <div className="mb-1">
                <Button
                    color="primary"
                    outline
                    onClick={() => {
                        onUpdate(skiShoeType);
                    }}
                    active={selectedShoeType === skiShoeType}
                >
                    {skiShoe}
                    <SkiIcon />
                </Button>
            </div>
            <p>или</p>
            <div>
                <Button
                    color="primary"
                    outline
                    onClick={() => {
                        onUpdate(snowboardShoeType);
                    }}
                    active={selectedShoeType === snowboardShoeType}
                >
                    {snowboardShoe}
                    <SnowboardIcon />
                </Button>
            </div>
        </div>
    )
}

ShoeTypeFilter.propTypes = {
    setFilters: PropTypes.func.isRequired,
    selectedShoeType: PropTypes.string,
};

export default ShoeTypeFilter;
